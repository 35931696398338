html {
  font-size: 10px; /* 1rem = 1px */
  -webkit-text-size-adjust:none;
  -webkit-font-smoothing: antialiased;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #c4cbcb;*/
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(top, #f0f0f0 50%, #a0adad 90%);
  background-image:    -moz-linear-gradient(top, #f0f0f0 50%, #a0adad 90%);
  background-image:      -o-linear-gradient(top, #f0f0f0 50%, #a0adad 90%);
  /*
  background-image:         linear-gradient(bottom, #f0f0f0 50%, #a0adad 90%);
  background-image:         linear-gradient(to bottom, #f0f0f0 50%, #a0adad 90%);
  */
  background-size: 100% auto;
  color: #888888;
  font-family: "맑은 고딕","Yoon Gothic","Apple Gothic","HY Dotum","HY Gulim","Lexi Gulim","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1.6em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color:#82b323;
  text-decoration:none;
  word-wrap: break-word;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s; 
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

a:focus,
a:hover {
  color:#e16253;
  outline: 0;
}

h1 {
  color: #333;
  font-family: "HY Gulim", "Lexi Gulim", 새굴림, "Lucida Grande", "Trebuchet MS", sans-serif;
  font-size: 1.6em;
  margin: 0.5em 0 1em 0;
  text-align: center;
  text-shadow: rgba(3, 3, 3, 0.3) 1px 1px 1px;
}

h2, h3, h4 {
  color: #333;
}

label, input, textarea {
  display: block;
  transition: color 0.4s ease, background-color 0.1s ease-in-out;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

hr {
    border-bottom: 1px solid #ccc;
}

#footer {
  clear: both;
  margin: 10px;
  font-size:0.8em;
  color: #555;
  word-break: keep-all;
}

#footer a {
  color: #333;
}

#footer a:hover {
  border-bottom: 1px solid #333;
  padding-bottom: 1px;
}

#footer .set {
 margin: 0 0.2em;
}

#footer .name {
  letter-spacing: -0.08em;
}

#footer .value {
  color: #333;
}

#footer .copyright {
  letter-spacing: -0.1em;
}

#footer p {
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

#footer .ftimage {
  background-color: rgba(255, 255, 255, 0.27);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  vertical-align: middle;
  height: 22px;
}
